<template>
    <header class="py-8 w-full">
        <div v-if="!loading">
            <!-- only display IF CLIENT HAS HIGH LEVEL alert -->
            <!-- make entire div clickable-->
            <div
                v-if="numHighAlerts > 0"
                class="rounded p-3 text-xs bg-red-light mb-6 flex items-center justify-between cursor-pointer"
                @click="rowClickHandler"
            >
                <div class="text-red-dark">
                    <fa-icon icon="exclamation-triangle" class="mr-1" />
                    This client has
                    <span class="font-bold">{{ numHighAlerts }} high level</span> alert(s).
                    <span v-if="alertTypes.length">The alert types include: </span>
                    <span class="font-bold">{{ typesOfAlerts }}</span>
                </div>
                <div class="font-bold text-xs text-red-dark">
                    View All
                    <fa-icon icon="chevron-right" class="ml-1" />
                </div>
            </div>
            <!-- end alert div-->
            <div class="header_main flex flex-row">
                <div class="pl-4">
                    <ProfileAvatar :showImage="false" />
                </div>
                <div class="flex flex-grow justify-between flex-col lg:flex-row">
                    <div class="flex flex-col">
                        <div class="flex flex-row items-center mb-1">
                            <h1
                                class="text-black mr-2 text-xl lg:text-2xl capitalize"
                                data-cy="name"
                            >
                                {{ clientName }}
                            </h1>
                            <div
                                v-if="aliases.length > 0"
                                class="rounded bg-grey-6 px-2 mx-1 text-xs text-grey-7 h-6 leading-6"
                            >
                                <span class="uppercase font-bold">ALIAS</span>
                                <span
                                    v-for="(alias, index) in aliases"
                                    :key="`alias-${index}`"
                                    class="inline-alias pl-1"
                                    >{{ getAliasDisplay(alias, index) }}</span
                                >
                            </div>
                        </div>
                        <div class="flex flex-row mb-1 lg:mb-2">
                            <div class="text-sm mr-5 whitespace-no-wrap">
                                <div class="uppercase text-xs mr-1 text-grey-2 font-bold inline">
                                    ID
                                </div>
                                {{ clientId }}
                            </div>
                            <div class="text-sm mr-5 whitespace-no-wrap">
                                <div class="uppercase text-xs mr-1 text-grey-2 font-bold inline">
                                    D.O.B
                                </div>
                                {{ dateOfBirth }}
                            </div>
                            <div class="text-sm whitespace-no-wrap mr-5">
                                <div class="uppercase text-xs mr-1 text-grey-2 font-bold inline">
                                    gender
                                </div>
                                {{ gender }}
                            </div>

                            <div class="text-sm whitespace-no-wrap mr-5">
                                <div class="uppercase text-xs mr-1 text-grey-2 font-bold inline">
                                    atsi
                                </div>
                                {{ getAtsiStatusText(client.atsiStatus) }}
                            </div>
                        </div>
                        <div class="flex flex-col mb-1 lg:mb-2 lg:flex-row">
                            <div class="text-sm mr-5 whitespace-no-wrap mb-1 lg:mb-0 capitalize">
                                <fa-icon icon="map-marker-alt" class="mr-1 text-grey-2" />
                                {{ location }}
                            </div>
                            <div class="text-sm whitespace-no-wrap mr-5" v-if="phoneNumbers.length">
                                <fa-icon icon="phone-alt" class="mr-1 text-grey-2" />
                                <span
                                    v-for="(phoneNumber, index) in phoneNumbers"
                                    :key="`alias-${index}`"
                                    class="inline-alias mr-4"
                                    >{{ phoneNumber }}</span
                                >
                            </div>
                        </div>
                        <div class="flex flex-row">
                            <div class="text-sm mr-5 whitespace-no-wrap" v-if="email">
                                <fa-icon icon="envelope" class="mr-1 text-grey-2 align-middle" />
                                {{ email }}
                            </div>
                            <div class="text-sm mr-5 whitespace-no-wrap" v-if="facebook">
                                <a
                                    :href="`https://www.facebook.com/${facebook}`"
                                    target="_blank"
                                    class="hover:text-primary-dark"
                                >
                                    <img
                                        src="@/assets/images/icon_facebook.png"
                                        class="social-icon inline mr-1"
                                    />
                                    {{ facebook }}
                                </a>
                            </div>
                            <div class="text-sm mr-5 whitespace-no-wrap" v-if="instagram">
                                <a
                                    :href="`https://www.instagram.com/${instagram}`"
                                    target="_blank"
                                    class="hover:text-primary-dark"
                                >
                                    <img
                                        src="@/assets/images/icon_instagram.png"
                                        class="social-icon inline mr-1"
                                    />
                                    {{ instagram }}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="text-left ml-0 mt-4 lg:mt-0 lg:ml-6 lg:text-right leading-4">
                        <div v-if="editable" class="flex flex-row">
                            <router-link
                                v-if="isStuni"
                                class="whitespace-no-wrap mr-2 lg:mb-0 mt-2 group uppercase text-sm text-green hover:text-green"
                                :to="{name: 'stuni-details', params: {userId: this.stuniId}}"
                            >
                                <fa-icon
                                    icon="external-link-alt"
                                    class="inline mr-1 group-hover:text-primary-dark"
                                />
                                <span
                                    class="font-semibold group-hover:text-green whitespace-no-wrap"
                                    >TSU Account</span
                                >
                            </router-link>

                            <v-menu bottom v-if="$can('view', 'subpoena')">
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn
                                        depressed
                                        small
                                        v-bind="attrs"
                                        v-on="on"
                                        class="bg-white-pure"
                                        :disabled="downloadingSubpoena"
                                        :class="{
                                            'disabled pointer-events-none': downloadingSubpoena
                                        }"
                                    >
                                        <div
                                            class="whitespace-no-wrap group uppercase text-sm text-primary-light hover:text-primary-dark"
                                        >
                                            <fa-icon
                                                icon="download"
                                                class="mr-1 group-hover:text-primary-dark"
                                            />
                                            <span
                                                class="font-semibold group-hover:text-primary-dark"
                                                >{{ subpoenaBtnTxt }}</span
                                            >
                                        </div>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item
                                        v-for="item in subpoenaList"
                                        :key="item.type"
                                        @click="downloadSubpoena(item.type)"
                                    >
                                        <v-list-item-title>{{
                                            toTitleCase(item.name)
                                        }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>

                            <router-link
                                class="pt-1.5 whitespace-no-wrap group uppercase text-sm text-primary-light hover:text-primary-dark"
                                :to="{name: 'edit-client', params: {clientId: clientId}}"
                                style="padding-top: 6px"
                            >
                                <fa-icon
                                    icon="pencil-alt"
                                    class="mr-1 group-hover:text-primary-dark"
                                />
                                <span class="font-semibold group-hover:text-primary-dark"
                                    >edit</span
                                >
                            </router-link>
                        </div>
                        <div class="mt-4 flex lg:flex-col">
                            <div
                                class="uppercase text-xs mr-2 lg:mr-0 lg:mb-2 text-grey-2 font-bold"
                            >
                                EMERGENCY CONTACT
                            </div>
                            <div v-if="isEmergencyContactNull" class="text-grey-2 text-sm">
                                None saved
                            </div>
                            <div v-else class="text-sm flex lg:flex-col">
                                <div class="mr-2 lg:mb-2 lg:mr-0">{{ emergencyContactName }}</div>
                                <div class="mr-2 lg:mb-2 lg:mr-0" v-if="emergencyContactPhone">
                                    {{ emergencyContactPhone }}
                                </div>
                                <div v-if="emergencyContactEmail">{{ emergencyContactEmail }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex items-center justify-center h-20" v-else>
            <Loader class="w-12 h-12" />
        </div>
    </header>
</template>

<script>
import ProfileAvatar from '../partials/ProfileAvatar'
import {computed, ref} from '@vue/composition-api'
import {mapState} from 'vuex'
import Loader from '@/assets/loader.svg'
import {format, parseISO} from 'date-fns'
import {storeToRefs} from 'pinia'
import {createNamespacedHelpers} from 'vuex-composition-helpers'
import axiosInstance from '@/utils/axios'
import {SharedMixin} from '@/helpers/displayValue'
import {downloadFile} from '@/utils/file.helpers'
import {useMessageStore} from '@/stores/useMessageStore'

const {useGetters} = createNamespacedHelpers('')

export default {
    name: 'Header',
    components: {
        Loader,
        ProfileAvatar
    },
    mixins: [SharedMixin],
    props: {
        client: {
            type: Object,
            default: () => {}
        },
        highAlerts: {
            type: Object,
            default: () => {}
        },
        editable: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            loading: true,
            numCertificateExpiryMonths: 18,
            numHighAlerts: 0,
            maxAlertTypes: 3,
            alertTypes: [],
            typesString: '',
            defaultContact: {
                firstName: '',
                lastName: '',
                phone: ''
            }
        }
    },
    mounted() {
        this.$root.$on(
            'submit-delete-client-and-related-data',
            this.handleSubmitDeleteCurrentClient
        )
    },
    methods: {
        getAliasDisplay(alias, index) {
            const comma = this.aliases && this.aliases.length - 1 === index ? '' : ','
            return `${alias}${comma}`
        },
        rowClickHandler() {
            // this.$store.commit('SET_CURRENT_EPISODE', item.episode)
            this.$router
                .push({
                    name: 'record-view',
                    params: {clientId: this.clientId, type: 'ALERT'}
                })
                .catch((err) => err)
        },
        toTitleCase(str) {
            return str
                .toLowerCase()
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')
        }
    },
    computed: {
        ...mapState({
            staticChoices: (state) => state.app.staticChoices,
            clientId: (state) => state.app.clientId
        }),
        clientName() {
            return this.client ? `${this.client.firstName} ${this.client.lastName}` : ''
        },
        aliases() {
            return this.client?.alias ? this.client.alias.split(',') : []
        },
        isStuni() {
            return (
                this.client?.user?.accountType === 'student' &&
                ['ACTIVE', 'DEACTIVATED', 'INVITED'].includes(this.client?.user?.status)
            )
        },
        stuniId() {
            return this.client?.user?.userId || ''
        },
        location() {
            const address = this.client ? this.client.address || {} : null
            if (!address) return ''
            console.log('this.client.address', this.client.address)

            const addressLines = `${address.addressLine1 || ''}${address.addressLine2 || ' '}`
            const unit = address.unit ? `Unit ${address.unit}, ` : ''
            const suburb = address.suburb || ''
            const state = address.state || ''
            const postcode = address.postcode || ''
            return address
                ? `${unit}${
                      addressLines.trim() ? addressLines + ', ' : ''
                  }${suburb} ${state} ${postcode}`.trim()
                : ''
        },
        phoneNumbers() {
            if (!this.client || Object.keys(this.client).length === 0) return []

            const numbers = [this.client.phone]
            return numbers.filter((n) => n != null)
        },
        dateOfBirth() {
            if (!this.client || Object.keys(this.client).length === 0 || !this.client.dob) return ''

            return format(parseISO(this.client.dob), 'dd MMM yyyy')
        },
        email() {
            return this.client?.email || ''
        },
        facebook() {
            return this.client?.facebook || ''
        },
        instagram() {
            return this.client?.instagram || ''
        },
        gender() {
            if (!this.client || Object.keys(this.client).length === 0) return ''
            const gender = this.genderOptions.find((s) => s.value === this.client.gender)
            return gender?.text || ''
        },
        emergencyContactName() {
            const name = this.client.emergencyContact
                ? `${this.client?.emergencyContact?.firstName || ''} ${
                      this.client?.emergencyContact?.lastName || ''
                  }`
                : ''
            return name.trim()
        },
        emergencyContactPhone() {
            return this.client?.emergencyContact?.phone || ''
        },
        emergencyContactEmail() {
            return this.client?.emergencyContact?.email || ''
        },
        isEmergencyContactNull() {
            return !`${this.emergencyContactName}${this.emergencyContactPhone}${this.emergencyContactEmail}`
                .length
        },
        typesOfAlerts() {
            let type = ''
            if (this.alertTypes.length) {
                type = this.alertTypes.slice(0, this.maxAlertTypes).join(', ')
                if (this.alertTypes.length > this.maxAlertTypes) type += ' ...'
            }
            return type
        }
    },
    watch: {
        highAlerts() {
            this.numHighAlerts = this.highAlerts?.aggregate.count
            this.highAlerts?.nodes.map((item) => {
                const type = this.getDisplayValue(item.type, this.types, 'text')
                if (!this.alertTypes.includes(type)) {
                    this.alertTypes.push(type)
                }
            })
        },
        client: {
            handler() {
                if (this.client?.clientId != null) this.loading = false
            },
            deep: true
        }
    },
    setup(props, {root}) {
        const $message = root.$message
        const messageStore = useMessageStore()
        const downloadingSubpoena = ref(false)
        console.log('===== props', props)
        const client = computed(() => props.client)
        const subpoenaList = ref([
            {
                name: 'Full ASSESSMENT',
                type: 'FULL_ASSESSMENT',
                endpoint: '/reports/client/:clientId/generate_subpoena_full_ass_report_pdf/'
            },
            {
                name: 'Brief ASSESSMENT',
                type: 'BRIEF_ASSESSMENT',
                endpoint: '/reports/client/:clientId/generate_subpoena_brief_ass_report_pdf/'
            },
            {
                name: 'Discharge Summary',
                type: 'DISCHARGE_SUMMARY',
                endpoint:
                    '/reports/client/:clientId/generate_subpoena_discharge_summary_report_pdf/'
            },
            {
                name: 'Medications',
                type: 'MEDICATIONS',
                endpoint: '/reports/client/:clientId/generate_subpoena_meds_report_pdf/'
            },
            {
                name: 'UTs',
                type: 'UTS',
                endpoint: '/reports/client/:clientId/generate_subpoena_uts_report_pdf/'
            }
        ])

        const {
            genderOptions,
            alertTypes: types,
            atsiStatuses
        } = useGetters(['genderOptions', 'alertTypes', 'atsiStatuses'])
        const {message, type} = storeToRefs(messageStore)
        const {showMessage, resetMessage} = messageStore
        const subpoenaBtnTxt = computed(() =>
            downloadingSubpoena.value ? 'Downloading...' : 'Subpoena'
        )
        const getAtsiStatusText = (atsiStatus) => {
            const atsi = atsiStatuses.value.find((s) => s.value === atsiStatus)
            return atsi?.text || ''
        }
        const downloadSubpoena = async (reportType) => {
            try {
                const item = subpoenaList.value.find((s) => s.type === reportType)
                downloadingSubpoena.value = true
                const data = await axiosInstance.get(
                    item.endpoint.replace(':clientId', client.value?.clientId),
                    {responseType: 'blob'}
                )
                downloadFile(data)
                message.value = 'Downloaded subpoena successfully.'
                type.value = 'success'
            } catch (error) {
                message.value =
                    'Failed to download report, please wait a few moments and try again.'
                type.value = 'error'
            } finally {
                downloadingSubpoena.value = false
                showMessage({duration: 5000}, $message)
            }
        }

        return {
            types,
            genderOptions,
            subpoenaList,
            subpoenaBtnTxt,
            downloadingSubpoena,
            getAtsiStatusText,
            downloadSubpoena
        }
    }
}
</script>
